
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "policy-invoices-table",
  components: {},
  props: {
    widgetClasses: String,
    receiptsSummary: Object,
    invoices: Array
  },
  setup() {
    const { useReusableNavigation } = ResusableFunctions();

    return { useReusableNavigation };
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
